<template>
    <div>
        <CCard>
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="diseasesFields" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { createNewDisease, getDiseasesData } from '@/services/diseases';
import AbstractForm from '@/components/Forms/AbstractForm.vue';
import { getFormAddDisease } from "@/utils/forms/diseases/form";

export default {
    name: "addDisease",
    components: { AbstractForm },
    data(){
      return{
        title: "Añadir una nueva enfermedad",
        diseases: [],
        id: null,
        diseasesFields: {
            name: null,
            desc: null
        },
        formContent: getFormAddDisease()
      }
    },
    created() {
        this.getDiseases();
     },
    methods: {
        onEmitMethods(event, dataDisease) {
            switch (event) {
                case 'add': this.addDisease(dataDisease); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        /**
       * Función en la que se obtienen las enfermedades
       * 
       * @returns {object[]} Las enfermedades obtenidas.
       */
       getDiseases() {
            getDiseasesData()
            .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() };})
            .then((result) => {
                this.diseases = result
            });
        },
        /**
         * Método para validar los inputs del formulario
         */
        checkInputs(dataDisease) {
            const specialCharsDescRegex = /^[a-zA-Z0-9,.!¡¿?()\sáéíóúÁÉÍÓÚüÜñÑ]+$/;
            const validationRules = [
                { name: 'Nombre', field: 'name', message: 'Introduzca el nombre (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Nombre', field: 'name', message: 'El nombre no debe contener caracteres especiales.', regex: specialCharsDescRegex },
                { name: 'Descripción', field: 'desc', message: 'Introduzca una descripción (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Descripción', field: 'desc', message: 'La descripción no debe contener caracteres especiales.', regex: specialCharsDescRegex },
            ];

            const existingDisease = this.diseases.find(disease => {
                return disease.name.toLowerCase() === dataDisease.name.toLowerCase()
            });
            
            if (existingDisease) {
                this.showAlertError(`El nombre "${dataDisease.name}" ya existe en las enfermedades.`);
                return false;
            }

            for (const rule of validationRules) {
                const value = dataDisease[rule.field];
                if (value === undefined || value === null) {
                    this.showAlertError(`El campo ${rule.name} está vacío`);
                    return false;
                }
                if(rule.maxLength && value.length > rule.maxLength){
                    this.showAlertError(`El número de caracteres de ${rule.name} debe ser menor o igual al siguiente valor: ${rule.maxLength}`);
                    return false;
                }
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataDisease[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        /**
         * Método para poder crear una nueva enfermedad
         */
        addDisease(dataDisease) {
            if (this.checkInputs(dataDisease) == false) return;
            createNewDisease(dataDisease)
                .then(() => {
                    this.$router.push("/diseases");
                }).catch((error) => {
                    this.$store.state.errorAlert = { status: true, msg: error.toString() } 
                })
        },
        /**
         * Función para mostrar el alert durante 10 segundos.
         */
        showFailureParameters() {
            this.contAlertParams = 10;
        },
        /**
         * Función para volver atrás
        */
        back() {
            this.$router.push("/diseases");
        },
    },
};
</script>